.module-header {
    position: relative;
    height: 450px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;

    .caption {
        position: absolute;
        top: 100%;
        width: 100%;
        background-color: $color-primary;
        text-align: center;
        padding: 10px;
        min-height: 80px;
        display: flex;
        align-items: center;
        justify-content: center;
        transform: translateY(-100%);

        h3 {
            color: #fff;
            width: 100%;
            font-size: 4vw;
            font-weight: 700;
            text-align: center;
            text-shadow: 2px 2px 7px #000;

            @media screen and (max-width: $size-xs-max){
              font-size: 26px;
            }
        }

    }
}
