section.section-black {
  background-color: $color-black;
  padding: 140px 10px;
  transition: .3s;


  @media screen and (max-width: $size-xs-max){
    padding: 60px 10px;
    transition: .3s;
  }


  div.container-fluid.row {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  div.content-box {
    max-width: 80%;
  }

  h3 {
    font-size: $font-size-34;
    color: $color-white;
    text-align: center;
    font-weight: 500;
    span {color: $color-primary;}
  }

  hr {
    border-top: 4px solid $color-primary;
    max-width: 150px;
    margin: 30px auto;
  }

  p {
    color: $color-white;
    text-align: center;
    @extend .font-2-normal;
  }
}
