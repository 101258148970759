// IMPORT ADDONS
@import '../vendors/bootstrap/css/bootstrap';
/* purgecss start ignore */
@import '../vendors/font-awesome/font-awesome';
@import '../vendors/owl-carousel2/owl.carousel';
@import '../vendors/magnific-popup/magnific-popup';
// @import '../vendors/cube-portfolio/cubeportfolio';
// @import '../vendors/parallax-scroll/normalize';
// @import '../vendors/parallax-scroll/main';
@import 'styles/alertify';
/* purgecss end ignore */

// IMPORT CUSTOMIZATION & RESET (order is important)
@import 'core/reset';
@import 'variables';
@import 'core/html';
@import 'utilities/functions';

// *******************
// Load basic classes (besoin de rester ici à la fin)
@import 'styles/fonts'; // utility classes for fonts
@import 'utilities/spacing'; // utility classes for spacing
@import 'utilities/flex'; // utility classes for flex box
@import 'utilities/responsive';
@import 'utilities/animation';

@import 'styles/colors'; // utility classes for colors
@import 'styles/hover';
@import 'styles/typography';
@import 'styles/buttons';

/* GENERAL TEMPLATE */
body {
    background-color: $bg-color-body;

    .parallax, .parallax-slow, .parallax-fast {
        background-attachment: fixed;
        @media screen and (max-width: $size-xs-max) {
            background-attachment: scroll;
        }
    }
}

/** Add this class to any anchor tag so it clear the fixed menu (used in forms) */
.jumptarget::before {
    content: "";
    display: block;
    height: $size-nav-height; /* fixed header height*/
    margin: -$size-nav-height 0 0; /* negative fixed header height */
}

.shadow {
    position: relative;

    &::after {
        content: '';
        z-index: 0;
        width: 100%;
        top: 100%;
        height: 40px;
        position: absolute;
        background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
    }
}

/*****************************************************/
/* Menu (to use with /modules/module-menu.php)
/* Or module-menu-centre.php
/*****************************************************/
@import 'modules/menu';
// @import 'menu-centre';


/*****************************************************/
/* Footer (to use with /modules/module-footer.php)
/*****************************************************/
@import 'styles/footer';

/*****************************************************/
/* Sections (to use with /sections/section-*-*.php)
/*****************************************************/
//@import 'sections/section-header-page';

//@import 'sections/section-text-image';



@import 'sections/section-black';

/*****************************************************/
/* Sections (to use with /modules/module-*-*.php)
/*****************************************************/
@import 'modules/module-slideshow';
//@import 'modules/module-soumission';
@import 'modules/module-header';



/*******************************************************************************
* COMMON TO ALL PAGES
*/


/*****************************************************/
/* INDEX.PHP
/*****************************************************/

section.bursts-section {
  padding: 0;
  margin: 0;

  div.container-fluid.row {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    & > div {
      width: 50%;
      transition: .5s;
    }

    @media screen and (max-width: $size-xs-max) {
      flex-direction: column;
      transition: .5s;
      & > div {
        width: 100%;
        transition: .5s;
      }
    }
  }

  @import 'sections/section-bursts';

  div.right-side {
    padding: 20px 0 20px 5%;


    h3 {
      font-size: $font-size-32;
      padding-bottom: 1rem;
      font-weight: 500;
      color: $color-grey;
    }

    ul {
      padding-left: 0;
      list-style: none;
      margin: 0;

      li {
        border-bottom: 1px solid $color-grey;
        text-transform: uppercase;
        font-size: $font-size-24;
        color: $color-grey;
        text-indent: -0.7em;
        padding-left: 1em;
        line-height: 1.5;
        font-weight: 500;
        &:before{
          content: "• ";
        }
      }

    }

    p {
      color: $color-grey;
      line-height: 1.3;
      padding: 20px 20px 20px 0;
      @extend .font-2-normal;
    }

    @media screen and (max-width: $size-xs-max){
      padding: 50px 20px;
      h3 {
        font-size: 24px;
        text-align: center;
      }
      ul > li {
        font-size: 20px;
        max-width: 150px;
        margin: auto;
      }
      p {
        font-size: 18px;
        text-align: center;
      }
    }

  }



}



section.horizontal-bursts {
  border-top: 20px solid $color-primary;

  div.container-fluid.row {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    & > div {
      width: 25%;
      transition: .3s;
      @media screen and (max-width: $size-xs-max){
        width: 50%;
        transition: .3s;
        @media screen and (max-width: $size-slider-breakpoint){
          width: 100%;
          transition: .3s;
        }
      }
    }
  }

  div.item {
    position: relative;
    overflow: hidden;
    a {
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      position: absolute;
    }
     &:hover {

       img {
         transform: scale(1.3);
         transition: .4s;
         &.overlay {
           opacity: 1;
           transition: .4s;
           visibility: visible;
         }
       }
     }

  }
  img {
    transform: scale(1);
    transition: .4s;

    &.overlay {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
      transition: .4s;
      visibility: hidden;

    }
  }


  div.plus-btn {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 40px;
    height: 40px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: $color-primary;
    color: $color-white;
    i {
      font-size: 25px;
    }
  }

}

/*******************************************************************************
* X PAGE
*/

section.section-bg-title {
  background-image: url('../images/recuperation_section2_img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 700px;
  position: relative;

  &.page-vente {
    background-image: url('../images/vente_section2_img.jpg');
  }

  div.caption {
    background-color: $bg-color-transparent-blue;
    padding: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .5s;
    h3 {
      font-size: 4vw;
      font-weight:400;
      transition: .5s;
      text-align: center;
      color: $color-white;
      span {
        font-size: 6vw;
        font-weight: 700;
        transition: .5s;
      }
      @media screen and (min-width: 1500px){
        font-size: 60px;
        span {
          font-size: 80px;
        }
      }
    }
  }
  div.container-fluid.row {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 130px;
    left: 0;
    right: 0;
    bottom: 0;
  }

  div.text-box {
    h4 {
      color: $color-white;
      font-size: $font-size-40;
      padding: 2rem 15px;
      max-width: 900px;
      line-height: 1.3;
      text-align: center;
      font-weight: 400;
    }
  }

  @media screen and (max-width: $size-xs-max){
    min-height: 400px;
    transition: .5s;
    div.caption h3 {
      font-size: 26px;
      transition: .5s;
      span {
        font-size: 26px;
        transition: .5s;
      }
    }

    div.container-fluid.row {
      top: 100px;
      transition: .5s;
    }
  }
}


section.section-text-image {
  background-image: url('../images/recuperation__section3_img.jpg');
  background-size: contain;
  background-repeat: no-repeat;
  padding: 50px 10px;
  background-position: center right 15%;
  @media screen and (max-width: $size-xs-max){
    background-image: none;
  }


  div.container-fluid.row {
    display: flex;
    align-items: center;
    justify-content: center;
    & > div {
      width: 50%;
      transition: .5s;
    }

    @media screen and (max-width: $size-xs-max) {
      flex-direction: column;
      transition: .5s;
      & > div {
        width: 100%;
        transition: .5s;
      }
    }
  }


  div.left-side {
    padding: 20px 0 20px 5%;
    display: flex;
    align-items: center;
    justify-content: center;

    h3 {
      font-size: $font-size-32;
      padding-bottom: 1rem;
      font-weight: 500;
      color: $color-grey;
    }

    ul {
      padding-left: 0;
      list-style: none;
      margin: 0;

      li {
        text-transform: uppercase;
        font-size: $font-size-24;
        color: $color-grey;
        text-indent: -0.7em;
        padding-left: 1em;
        line-height: 1.5;
        font-weight: 500;
        &:before{
          content: "• ";
        }
      }

    }



    @media screen and (max-width: $size-xs-max){
      padding: 50px 20px;
      h3 {
        font-size: 24px;
        text-align: center;
      }
      ul > li {
        font-size: 20px;
        max-width: 150px;
        margin: auto;
      }

    }

  }
}


section.section-bg-text {
  background-image: url('../images/vehicules_section2_img.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  min-height: 550px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: 15px;


  h4 {
    max-width: 1170px;
    color: $color-white;
    text-align: center;
    font-size: $font-size-40;
    text-transform: uppercase;
  }
}


section.section-text-carousel {
  background: $color-white;
  border-top: 30px solid $color-primary;

  div.container-fluid.row {
    display: flex;
    align-items: stretch;
    justify-content: center;
    & > div {
      width: 50%;
      transition: .5s;
    }

    @media screen and (max-width: $size-xs-max) {
      flex-direction: column;
      transition: .5s;
      & > div {
        width: 100%;
        transition: .5s;
      }
    }
  }



    div.left-side {
      padding: 20px 0 20px 5%;
      display: flex;
      align-items: center;
      justify-content: center;

      h3 {
        font-size: $font-size-32;
        padding-bottom: 1rem;
        font-weight: 500;
        color: $color-grey;
      }

      ul {
        padding-left: 0;
        list-style: none;
        margin: 0;

        li {
          text-transform: uppercase;
          font-size: $font-size-24;
          color: $color-grey;
          text-indent: -0.7em;
          padding-left: 1em;
          line-height: 1.5;
          font-weight: 500;
          &:before{
            content: "• ";
          }
        }

      }



      @media screen and (max-width: $size-xs-max){
        padding: 50px 20px;
        h3 {
          font-size: 24px;
          text-align: center;
        }
        ul > li {
          font-size: 20px;
          max-width: 150px;
          margin: auto;
        }

      }

    }


}


//***************************************************
// VENTE D’ACIER

section.section-text-burst {

  div.container-fluid.row {
      margin: 0;
      padding: 0;

      display: flex;
      align-items: stretch;
      justify-content: space-around;

      @media screen and (max-width: $size-slider-breakpoint){
        flex-direction: column;
        transition: .5s;
      }
  }

  div.text-side {
    width: 75%;
    background-color: $color-3;
    border-top: 50px solid $color-primary;

    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: $size-slider-breakpoint){
      width: 100%;
      transition: .5s;
      padding: 30px 15px;
    }

    div.text-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      align-items: stretch;
      justify-content: space-around;
      transition: .5s;
      h3 {
        color: $color-white;
        font-size: 35px;
        font-weight: 400;
      }
      p {
        color: $color-white;
        font-weight: 400;
        line-height: 1.5;
        font-size: 20px;
        margin: 0;
        padding: 0;
      }

      @media screen and (max-width: $size-sm-max){
        h3 {
          font-size: 26px;
          transition: .5s;
        }
        p {
          font-size: 18px;
          transition: .5s;
        }
        @media screen and (max-width: $size-xs-max){
          flex-direction: column;
          align-items: center;
          transition: .5s;
          div.text-box {
            transition: .5s;
            width: 200px;
          }
        }

      }
    }

  }

  @import 'sections/section-bursts';

  div.bursts {
    div.bursts-container {
      width: 100%;
      transition: .5s;
    }
  }

}


section.section-fer-table {
  padding: 30px 0;
  border-top: 50px solid $color-primary;

    // .table > tbody > tr > th {
    //   border-top: 1 solid #fff;
    // }
    tr {
      height: 40px;
      &:nth-child(2n) {
        background-color: $color-grey-lighter;
      }
    }
    td {
      padding: 5px 10px;
    }

    h4 {
      text-transform: uppercase;
      color: $color-primary;
      font-size: $font-size-30;
    }
    p {
      margin: 0;
      font-weight: 400;
      line-height: 1.5;
      color: $color-black;
      font-size: $font-size-22;
    }



}




.pageX {
    section.header {

    }
}

/*******************************************************************************
* page liste de Prix
*******************************************************************************/
.section-liste-prix {
    .plus-box {
        background-color: $color-white;
        margin: 60px 0;
      //  padding: $ratio5;
        a {
            color: $color-white;
            background: $color-primary;
            padding: 10px 0;
            i {
                margin-right: $ratio3;
                color: $color-white;
                transition: 0.2s all;
                color: $color-primary;

            }
            div.icon {
              position: relative;
              width: 31px;
              height: 25px;

              img.toggled {
                opacity: 0;
                z-index: 1;
                transition: .5s;
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
              }
              img.toggle {
                transition: .5s;
              }
            }
            h4 {
                @extend .text-uppercase;
                font-weight: 400;
                font-size: $font-size-40;
                margin: 0 0;
                //margin-left: auto;
            }
        }
        a[aria-expanded="true"] {
          div.icon {
            position: relative;
            img.toggle {
              transform: rotate(180deg);
              transition: .5s;
              opacity: 0;
            }
            img.toggled {
              opacity: 1;
              transition: .5s;
              transform: rotate(180deg);
            }
          }

            h4 {

            }
        }
        .collapse .well, .collapsing .well {
            background-color: $color-white;
            border: none;
            padding: 0;
            box-shadow: none;
            table {
                th {
                    p {
                        color: $color-primary;
                        font-size: $font-size-30;
                        text-transform: uppercase;
                    }
                }
                tr:nth-child(2n) {
                  background: $color-grey-lighter;
                }
                p {margin: 0;}
            }
        }
        .name {
            p {
                font-weight: bold;
                text-transform: uppercase;
            }
        }
        .price {
            p {
                color: $color-black;
                span {
                    color: $color-black;
                }
            }
        }
        .modification p, .unit p, .specification p {
            font-weight: normal;
        }
    }
    @media screen and (max-width: $size-xs-max) {
        .plus-box {
            margin: $ratio5 0;
            .separator {
                background-color: $color-blue-light;
                height: 25px;
                margin: 0 -2px 10px -2px;
            }
            .name p, .price p, .specification p {
                margin-bottom: $ratio1;
            }
            .specification p, .modification p {
                font-size: $font-size-18;
            }
            .collapse .well, .collapsing .well {
                padding-left: 10px;
                padding-right: 10px;;
            }
        }
    }
}


/*******************************************************************************
* page Coordonnees
*******************************************************************************/
section.section-01-coordonnees {
  background: $color-black;
  padding: 50px 0;

  div.text-box {
    h3 {
      transition: .5s;
      font-weight: 400;
      color: $color-primary;
      font-size: $font-size-60;
    }
    p {
      font-size: $font-size-30;
      color: $color-white;
      font-weight: 400;
      line-height: 1;
      transition: .5s;
    }
    
    @media screen and (max-width: $size-xs-max){
      h3, p {
        text-align:center;
        transition: .5s;
      }
    }
  }
}

/*******************************************************************************
* MOBILE OVERRIDES FOR ALL SECTIONS
*******************************************************************************/
@media screen and (max-width: $size-md-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}

@media screen and (max-width: $size-sm-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */

}


@media screen and (max-width: $size-xs-max) {
    /*******************************************************************************
    * COMMON TO ALL PAGES
    */
    section.header {

    }

    /*******************************************************************************
    * X PAGE OVERRIDES
    */
}
