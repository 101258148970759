div.bursts {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: center;
    .bursts-container {
        width: 50%;
        margin: 0;
        transition: .4s;

        div.burst {
          position: relative;

          div.image-wrapper {

          }

          div.text-container {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            div.text-wrapper {
              height: 60%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;

              // border-top: 3px solid $color-primary;
              // border-bottom: 3px solid $color-primary;
            }
          }

          div.overlay {
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              width: 100%;
              opacity: 0;
              visibility: hidden;
              transition: all 0.7s ease-out; // OUT effect
          }

          hr {
            border-top: 4px solid $color-grey;
            width: 75%;
            box-shadow: 2px 2px 2px $color-white,
                         -2px -2px 2px $color-white,
                         2px -2px 2px $color-white,
                         -2px 2px 2px $color-white;
          }

          h3 {
              transition: .4s;
              font-size: 2vw;
              font-weight: 600;
              text-align: center;
              color: $color-grey;
              text-shadow: 2px 2px 2px $color-white,
                           -2px -2px 2px $color-white,
                           2px -2px 2px $color-white,
                           -2px 2px 2px $color-white;
              text-transform: uppercase!important;

          }

        }
        &.darker {
          background: $color-1;
          .burst {
            h3 {
                color: $color-primary;
              }
          }
        }


        &:hover {
          //transform: scale(1.2);
          transition: .4s;
          z-index: 3;
          .burst {
            h3 {
              font-style: italic;
              transition: .4s;
            }
            .overlay {
              opacity: 1;
              transition: .4s;
              visibility: visible;
            }
          }
          &.darker {
            transition: .4s;
            .burst {
              hr {
                border-top: 4px solid $color-primary;
                transition: .4s;
              }
            }
          }

        }

    }
}
@media screen and (max-width: $size-xs-max) {
  div.bursts {
      .bursts-container {
          div.burst {
              h3 {
                  font-size: 15px;
              }
          }
      }
  }
  @media screen and (max-width: $size-slider-breakpoint) {
      div.bursts {
          flex-direction: column;
          .bursts-container {
              width: 100%;
              div.burst {
                  h3 {
                      font-size: 15px;
                  }
              }
          }
      }
  }
}
